import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import test from "../assets/test.pdf";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/billview.css";
import moment from "moment";
// import { ProgressBar } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { ImEqualizer2 } from "react-icons/im";
import {
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoMdArrowDropright,
} from "react-icons/io";
import axios from "axios";
import Global from "../global";
import { FiArrowLeft } from "react-icons/fi";
import { IoMdAddCircle } from "react-icons/io";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { FaFileInvoiceDollar } from "react-icons/fa";

// import { savePDF } from "@progress/kendo-react-pdf";
// import html2pdf from "html2pdf.js";
import TablePagination from "@material-ui/core/TablePagination";
import { IconContext } from "react-icons";
import Select from "react-select";
import { MdKeyboardBackspace } from "react-icons/md";
import { useLocation, useHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "@material-ui/core";
import AmpathLogo from "../assets/images/ampath-fulllogo.jpg";

const InvoiceView = () => {
  const location = useLocation();
  const history = useHistory();
  const [invoice, setInvoice] = useState({});
  const [invoiceItem, setInvoiceItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [downloadLoading, setDownloadLoading] = useState(true);
  const [blobFile, setBlobFile] = useState("");
  const [file, setFile] = useState();

  console.log("location", location);

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    async function fetchPDF() {
      try {
        const invoiceData = await axios.post(
          Global.base_url + "/user/invoice/getData",
          { invoice_id: location.state.data.invoice_id },
          config
        );

        if (invoiceData.status === 200) {
          console.log("invoice daata", invoiceData);
          setInvoice(invoiceData.data);
          let invoice_items = [];
          let result = {};
          invoiceData.data.items.forEach(function (item) {
            let key = item.package_id
              ? `p${item.package_id}`
              : `t${item.test_id}`;
            let existKeys = Object.keys(result);

            if (existKeys.includes(key)) {
              result[key].quantity = result[key].quantity + item.quantity;
            } else {
              result[key] = item;
            }
          });
          Object.keys(result).forEach((item, i) => {
            let obj = {
              id: i + 1,
              package_name: result[item].package_name || result[item].test_name,
              qty: result[item].quantity,
              unit_price: result[item].package_price || result[item].test_price,
              total:
                result[item].quantity *
                (result[item].package_price || result[item].test_price),
            };
            invoice_items.push(obj);
          });
          setInvoiceItem(invoice_items);

          let total = invoice_items.reduce((n, { total }) => n + total, 0);

          setTotalAmount(total);
          // console.log("")
          try {
            const response = await axios.post(
              Global.base_url + "/user/invoice/download",
              { invoice_id: location.state.data.invoice_id },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                responseType: "blob",
              }
            );
            // console.log(response);

            if (response.status === 200) {
              console.log("blob response", response);
              var convertedBlob = new File(
                [response.data],
                `Invoice_${location.state.data.invoice_id}.pdf`
              );

              console.log("converted file", convertedBlob);
              setFile(convertedBlob);
              setBlobFile(response.data);
            }
          } catch (error) {
            if (error.response) {
              if (error.response.status === 401) {
                toast.error("PDF Invalid");
                console.log("PDF error", error);
                return;
              }
            }
          }
          return setLoading(false);
        }
      } catch (error) {
        // console.log("errror", error);
        if (error.invoiceData) {
          if (error.invoiceData.status === 401) {
            // console.log("admin product error", error);
            // setLoading(true);
            return toast.error("Unable to get invoice data");
          }
          if (error.invoiceData.status === 500) {
            // console.log("admin product error", error);
            // setLoading(true);
            return toast.error("Internal server error");
          }
        }
      }
    }
    fetchPDF();
  }, []);

  const downloadPDF = () => {
    // console.log("blob file", blobFile);
    const url = window.URL.createObjectURL(new Blob([blobFile]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Invoice_${location.state.data.invoice_id}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <section
          style={{
            width: "100%",
            backgroundColor: "#fff",
            position: "relative",
            minHeight: "89vh",
          }}
        >
          <div
            className="col-12 bg-white align-items-center pt-3 pb-3 justify-content-between"
            style={{
              borderBottom: "1px solid #B3CDFF",
              display: "flex",
              alignItems: "center",
              position: "sticky",
              top: "68px",
              zIndex: 47,
            }}
          >
            <div className="d-flex align-items-center">
              <IconButton
                onClick={() => {
                  history.push("/user/invoices");
                }}
              >
                <IconContext.Provider value={{ size: "30px" }}>
                  <FiArrowLeft />
                </IconContext.Provider>
              </IconButton>
              <p
                style={{
                  cursor: "pointer",
                  fontSize: "25px",
                  marginLeft: "5px",
                }}
                className="semiFont"
              >
                Back
              </p>
            </div>
            <Button
              variant="contained"
              className="primary__btn"
              id="admin-reportview-download-btn"
              style={{
                padding: "10px 25px",

                color: "#fff",
              }}
              onClick={downloadPDF}
            >
              Download
            </Button>
          </div>
          {invoice ? (
            <div className="pdf__container pb-3">
              <div className="pdf__header">
                <div className="pdf__image">
                  <img src={AmpathLogo} alt="ampath_logo" loading="lazy" />
                </div>
                <div className="pdf__address mediumFont">
                  <p className="pdf__labname semiFont">AMPATH</p>
                  <p className="pdf__labAddress">
                    Plot number 8-11, 2nd Floor,
                  </p>
                  <p className="pdf__labphone">(123) 456-789</p>
                  <p className="pdf__labemail">ampath@exmaple.com</p>
                </div>
              </div>
              {invoice.invoice.length ? (
                <div className="pdf__content py-3">
                  <div className="pdf__invoiceAddress mediumFont">
                    <p className="invoice__title">INVOICE TO:</p>
                    <p className="invoice__name">
                      {invoice.invoice[0].invoice_name}
                    </p>
                    <p className="invoice__details">
                      {invoice.invoice[0].invoice_address}
                    </p>
                    <p className="invoice__details">
                      {invoice.invoice[0].city}, {invoice.invoice[0].state}
                      {", "}
                      {invoice.invoice[0].country}{" "}
                    </p>
                    <p className="invoice__details">
                      {invoice.invoice[0].invoice_phone}
                    </p>
                  </div>
                  <div className="pdf__invoiceAddress mediumFont pdf__address2">
                    <p className="invoice__title">
                      INVOICE #{invoice.invoice[0].invoice_id}
                    </p>
                    <p className="invoice__details">
                      Date of invoice :{" "}
                      {moment(invoice.invoice[0].created_at).format(
                        "DD-MM-YYYY"
                      )}
                    </p>
                  </div>
                </div>
              ) : null}
              <div style={{ overflowX: "auto" }}>
                <table className="pdf__table">
                  <thead className="semiFont">
                    <th className="table__content width-10">ID</th>
                    <th className="table__content width-40">PACKAGE / TEST</th>
                    <th className="table__content width-20">UNIT PRICE</th>
                    <th className="table__content width-10">QTY</th>
                    <th className="table__content width-20">TOTAL</th>
                  </thead>
                  <tbody className="mediumFont">
                    {invoiceItem.length
                      ? invoiceItem.map((item, i) => {
                          return (
                            <tr>
                              <td className="table__content width-10">
                                {i + 1}
                              </td>
                              <td className="table__content width-40">
                                {item.package_name}
                              </td>
                              <td className="table__content width-20">
                                {item.unit_price}
                              </td>
                              <td className="table__content width-10">
                                {item.qty}
                              </td>
                              <td className="table__content width-20">
                                &#8377; {item.total}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                  <tfoot className="pdf__table--footer mediumFont">
                    <tr>
                      <td colSpan="3"></td>
                      <td colSpan="1" className="table__content">
                        SUBTOTAL
                      </td>
                      <td className="table__content">&#8377; {totalAmount}</td>
                    </tr>
                    <tr>
                      <td colSpan="3"></td>
                      <td colSpan="1" className="table__content">
                        TAX 0%
                      </td>
                      <td className="table__content">&#8377; 0.00</td>
                    </tr>
                    <tr>
                      <td colSpan="3"></td>
                      <td className="table__content" colSpan="1">
                        GRAND TOTAL
                      </td>
                      <td className="table__content">&#8377; {totalAmount}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <hr className="pdf__hr" />
              <div className="table__content mediumFont">
                Invoice was created on a computer and is valid without the
                signature and seal.
              </div>
            </div>
          ) : null}
        </section>
      )}
    </>
  );
};

export default InvoiceView;
