import React, { useState, useEffect } from "react";
import "../assets/css/login.css";
import LoginSideContent from "./LoginSideContent";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { IconContext } from "react-icons";
import LoginLogo from "../assets/images/ampath.jpg";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import Global from "../global";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const LoginPage = (props) => {
  const history = useHistory();
  const [signComplete, setSignComplete] = useState(false);
  const [forgetComplete, setForgetComplete] = useState(false);
  // console.log("isforget", forgetComplete);
  // const [remember, setRemember] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secureText, setSecureText] = useState(true);
  const [blankEmail, setBlankEmail] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [blankPassword, setBlankPassword] = useState(false);
  const [validLength, setValidLength] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    // getting values from signup complete and forget page
    if (props.location.success) {
      setSignComplete(props.location.success.isComplete);
      setForgetComplete(props.location.success.isForget);
    }
  }, []);

  // const handleRemember = () => {
  //   setRemember(!remember);
  // };

  // email regex
  const emailReg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  // login handler
  const handleLogin = async (e) => {
    e.preventDefault();

    if (email.length === 0) {
      setBlankEmail(true);
      setInvalid(false);
    } else {
      setBlankEmail(false);
    }

    if (emailReg.test(email) === false) {
      setValidEmail(true);
      setInvalid(false);
    } else {
      setValidEmail(false);
    }

    if (password.length === 0) {
      setBlankPassword(true);
      setInvalid(false);
    } else {
      setBlankPassword(false);
    }

    if (password.length >= 8 && password.length <= 15) {
      setValidLength(false);
    } else {
      setValidLength(true);
      setInvalid(false);
    }

    if (
      emailReg.test(email) === true &&
      email.length > 0 &&
      password.length >= 8
    ) {
      setBlankEmail(false);
      setBlankPassword(false);
      setValidEmail(false);
      setValidLength(false);
      setInvalid(false);
      setLoading(true);

      try {
        const response = await axios.post(
          Global.base_url + "/user/login",
          {
            email: email,
            password: password,
          },
          Global.headers
        );
        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("active-sidebar", 0);
          localStorage.setItem("user_name", response.data.username);

          //redirect to dashboard page
          await history.push("/user/dashboard");
          console.log("login response", response);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.status !== undefined
        ) {
          return setLoading(false), setInvalid(true);
        }

        setLoading(false);
      }
    }
  };

  // Toast bar close handler
  const handleClose = (event, reason) => {
    console.log("event tracker", { event: event, reason: reason });
    if (signComplete) {
      setSignComplete(false);
    } else if (forgetComplete) {
      setForgetComplete(false);
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <section className="login__container">
      <Snackbar
        open={forgetComplete}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Password Changed Successully"
        action={action}
      />
      <Snackbar
        open={signComplete}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Signup Completed Successfully"
        action={action}
      />
      <div className="login__section">
        <div className="login__elements">
          <img src={LoginLogo} alt="Ampath logo" className="login__logo" />
          <h2
            className="hkBoldFont login__header"
            style={{ marginTop: "10px" }}
          >
            Welcome back.
          </h2>
          <form onSubmit={handleLogin}>
            <div className="inputBox ">
              <label htmlFor="email" className="semiFont login__label">
                Email address
              </label>
              <input
                type="text"
                name="email"
                placeholder="Enter Email"
                value={email}
                onChange={(name) => setEmail(name.target.value)}
                style={{
                  borderColor: blankEmail || validEmail ? "#cc0000" : "#c3cad9",
                }}
              />
              <p
                className="err"
                style={{
                  display: validEmail ? "block" : "none",
                  transition: "0.3s",
                }}
              >
                Email must be valid
              </p>
              <p
                className="err"
                style={{
                  display: blankEmail ? "block" : "none",
                  transition: "0.3s",
                }}
              >
                Email can't be blank
              </p>
            </div>
            <div className="inputBox ">
              <label htmlFor="password" className="semiFont login__label">
                Password
              </label>
              <input
                name="password"
                placeholder="Enter Password"
                type={secureText ? "password" : "text"}
                value={password}
                onChange={(name) => setPassword(name.target.value)}
                style={{
                  borderColor:
                    blankPassword || validLength ? "#cc0000" : "#c3cad9",
                }}
              />

              <p
                className="err"
                style={{
                  display: blankPassword ? "block" : "none",
                  transition: "0.3s",
                }}
              >
                Password can't be blank
              </p>
              <p
                className="err"
                style={{
                  display: validLength ? "block" : "none",
                  transition: "0.3s",
                }}
              >
                Password must have atleast 8 char
              </p>

              <div
                className="password__icon"
                onClick={() =>
                  // Shows password when clicking eye icon
                  secureText ? setSecureText(false) : setSecureText(true)
                }
              >
                <IconContext.Provider
                  value={{
                    color: "#959EAD",
                    size: "20px",
                  }}
                >
                  {secureText ? <VscEye /> : <VscEyeClosed />}
                </IconContext.Provider>
              </div>
            </div>

            <div
              style={{
                marginTop: "5px",
                fontFamily: "semiFont",
                display: invalid ? "block" : "none",
              }}
            >
              <p style={{ color: "#cc0000", fontFamily: "mediumFont" }}>
                Invalid email and password
              </p>
            </div>

            <div className="login__options" style={{ marginTop: "15px" }}>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={remember}
                    onChange={handleRemember}
                    name="Keep me sign in"
                  />
                }
                label="Keep me sign in"
              /> */}
              <p
                className="login__forget"
                onClick={() => history.push("/forget-password")}
              >
                Forgot password?
              </p>
            </div>
            <Button
              variant="contained"
              color="secondary"
              className="login__btn"
              type="submit"
              disabled={loading}
            >
              {/* loading successfull when email and password valid */}
              {loading && !validEmail && !blankPassword ? (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "sign in"
              )}
            </Button>
          </form>
          <div className="login__redirect">
            <p className="create__accText">Don’t have an account?</p>
            <p className="create__acc" onClick={() => history.push("/signup")}>
              Sign up now
            </p>
          </div>
        </div>
      </div>

      {/* login article content */}
      <LoginSideContent />
    </section>
  );
};

export default LoginPage;
