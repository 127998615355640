import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import csc from "country-state-city";
import { Formik, ErrorMessage, Field, FieldArray, Form } from "formik";
import * as yup from "yup";
import "yup-phone";
import "../assets/css/cart.css";
import { useHistory } from "react-router";
import emptyCart from "../assets/images/empty_cart.png";
// import paypal from "../assets/images/paypal.png";
// import CreditCardInput from "react-credit-card-input";
import Global from "../global";
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import { BiRupee } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import Loader from "react-loader-spinner";
import axios from "axios";
import Select from "react-select";
import { propTypes } from "react-bootstrap/esm/Image";
import { FaCartPlus } from "react-icons/fa";
import { DateTimePicker } from "@material-ui/pickers";
import { IconContext } from "react-icons";
import MomentUtils from "@date-io/moment";
import { toast, ToastContainer } from "react-toastify";
import { FiArrowLeft } from "react-icons/fi";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";

const Cart = (props) => {
  const history = useHistory();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
  const requiredField = <span style={{ color: "red" }}>*</span>;
  //   const [countries, setCountries] = useState([{ name: "Choose Country" }]);

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [cartData, setCartData] = useState([]);

  const [memberOptions, setMemberOptions] = useState([]);
  //   let userdata = [];

  const [cartItems, setCartItems] = useState([]);
  const [trigger, setTrigger] = useState("");

  useEffect(() => {
    async function fetchinfo() {
      try {
        const result = await axios.post(
          Global.base_url + "/user/cart",
          null,
          config
        );

        // console.log(result);
        if (result.status === 200) {
          console.log("cart response", result);
          setCartData(result.data.data);

          let filteredCartData = result.data.data.map((product) => {
            return {
              item_id: product.item_id,
              cart_id: product.cart_id,
              member_id: product.member_id
                ? {
                    label: `${product.firstname} ${product.lastname}`,
                    value: product.member_id,
                  }
                : { label: "Choose Member", value: "" },
              appointment: product.appointment
                ? moment(product.appointment).format("YYYY-MM-DD") <
                  moment().format("YYYY-MM-DD")
                  ? moment().format("YYYY-MM-DD")
                  : moment(product.appointment).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD"),
              lab_name: product.lab_name,
              product_name: product.package_name
                ? product.package_name
                : product.test_name,
              price: product.price ? product.price : product.test_price,
              lab_status: product.lab_status,
              product_status: product.package_status
                ? product.package_status
                : product.test_status,
              type: product.type,
            };
          });
          setCartItems(filteredCartData);
          try {
            const memberResponse = await axios.post(
              Global.base_url + "/user/cart/members",
              null,
              config
            );
            // console.log(memberResponse);

            if (memberResponse.status === 200) {
              console.log("member response", memberResponse);
              let members = memberResponse.data.data.map((item) => {
                return {
                  label: `${item.firstname} ${item.lastname} (${item.relationship})`,
                  value: item.member_id,
                };
              });
              setMemberOptions([
                { label: "Choose Member", value: "" },
                ...members,
              ]);
            }
          } catch (error) {
            if (error.memberResponse) {
              if (error.memberResponse.status === 401) {
                console.log("admin product error", error);
                return;
              }
            }
          }
          return setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            console.log("user error", error.response.status);
            return;
          }
        }
      }
    }

    fetchinfo();
  }, [trigger]);

  console.log("cart datrsasa", cartItems);

  const required = <span style={{ color: "red" }}>*</span>;

  const removeItem = async (user) => {
    console.log("remove item", user);

    try {
      const response = await axios.post(
        Global.base_url + "/user/cart/remove",
        {
          cart_id: user.cart_id,
          item_id: user.item_id,
        },
        config
      );

      // console.log("addpatien", response);

      if (response.status === 200) {
        toast.success("Item removed from cart");
        return setTrigger(Date.now() + 1);
        // return props.history.replace("/userDashboard");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Item removal failed");
      }
    }
  };

  const checkAvailable = (item) => {
    console.log("item asjgf,jasuhfdj,afh", item);
    let unAvailableItems = item.userdata.filter((list) => {
      return list.product_status && list.lab_status ? null : list;
    });

    if (unAvailableItems.length) {
      toast.error("Please remove unavailable products");
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <>
          <div className="payment-container">
            {/* <div className="cart__container  mb-4 px-5 py-3"> */}
            <nav className="cart_headContainer navbar w-100   navbar-light pb-2">
              <div className="test__centerTitle">
                <IconButton onClick={() => history.push("/user/dashboard")}>
                  <IconContext.Provider value={{ size: "30px" }}>
                    <FiArrowLeft />
                  </IconContext.Provider>
                </IconButton>
                <p
                  className="boldFont"
                  style={{
                    color: "#161616",
                    fontSize: "25px",
                    marginLeft: "10px",
                  }}
                >
                  Cart
                </p>
                {/* <IoMdClose onClick={() => history.push("/user/dashboard")} /> */}
              </div>
              {/* <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button> */}
              {/* <div
                className=" justify-content-end"
                // id="navbarNav"
              >
                <IconButton
                  aria-label="delete"
                  onClick={() => history.push("/user/dashboard")}
                >
                  <IconContext.Provider value={{ size: "25px" }}>
                    <IoMdClose />
                  </IconContext.Provider>
                </IconButton>
              </div> */}
            </nav>

            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                userdata: cartItems && cartItems,
              }}
              enableReinitialize={true}
              validationSchema={yup.object({
                userdata: yup.array().of(
                  yup.object({
                    member_id: yup.object({
                      value: yup.number().required("Member id is required"),
                    }),
                    // age: yup
                    //   .number()
                    //   .min(3, "Minimun age is 3")
                    //   .max(100, "Max age is 100")
                    //   .required("Age is required"),
                    appointment: yup
                      .date()
                      .required("Date cannot be empty")
                      //   .max(
                      //     moment().format("YYYY-MM-DD"),
                      //     "Future Date cannot be Accepted"
                      //   )
                      .nullable(),
                  })
                ),
              })}
              onSubmit={async (values, formik) => {
                setSubmitLoading(true);
                // let data = [];

                let submittValues = values.userdata.map((product) => {
                  return {
                    item_id: product.item_id,
                    cart_id: product.cart_id,
                    member_id: product.member_id.value,
                    appointment: product.appointment,
                    lab_name: product.lab_name,
                    product_name: product.product_name,
                    price: product.price,
                    lab_status: product.lab_status,
                    product_status: product.product_status,
                    type: product.type,
                  };
                });
                console.log("foasdlkasgdkagsdkjagsrm", submittValues);
                try {
                  const response = await axios.post(
                    Global.base_url + "/user/cart/submit",
                    {
                      items: submittValues,
                    },
                    config2
                  );

                  // console.log("addpatien", response);

                  if (response.status === 200) {
                    setSubmitLoading(false);
                    // setToggle(true);
                    return history.push("/user/checkout");
                  }
                } catch (error) {
                  if (error.response && error.response.status === 401) {
                    setSubmitLoading(false);
                    toast.error("Unable to submit");
                    return console.log("admin delete user error", error);
                  }
                }
              }}
            >
              {(formik) => {
                console.log("formik", formik.errors);
                // console.log("formik values", formik.values);
                let sum = formik.values.userdata.reduce(
                  (n, { price }) => n + price,
                  0
                );

                // console.log("total sum", sum);
                return (
                  <>
                    <div className="cart__itemContainer">
                      {cartItems.length > 0 ? (
                        <>
                          {formik.values.userdata.map((user, x) => {
                            // console.log("user", user);

                            return (
                              <>
                                <div className="card__item mt-2 p-4" key={x}>
                                  <div
                                    className="card__title--row"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h2 className="cart__header hkBoldFont">
                                      {user.product_name}
                                      {/* <p className="">
                                    
                                    
                                      : "Unavailable"}
                                  </p> */}
                                      {user.lab_status &&
                                      user.product_status ? null : (
                                        <p style={{ color: "red" }}>
                                          (Unavailable)
                                        </p>
                                      )}
                                    </h2>
                                    <IconButton
                                      onClick={() => removeItem(user)}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          color: "red",
                                          size: "30px",
                                          //   marginTop: "15px",
                                        }}
                                      >
                                        <AiOutlineDelete />
                                      </IconContext.Provider>
                                    </IconButton>
                                  </div>
                                  <div className="cart__priceContainer mt-2">
                                    <p className="cart__title">Price:</p>
                                    <p className="cart__price ml-2">
                                      <IconContext.Provider
                                        value={{
                                          color: "#616161",
                                          size: "20px",
                                          //   style: { marginBottom: "3px" },
                                        }}
                                      >
                                        <BiRupee />
                                      </IconContext.Provider>
                                      {user.price}
                                    </p>
                                  </div>
                                  <div className="cart__priceContainer mt-2">
                                    <p className="cart__title">Lab Name:</p>
                                    <p className="cart__price ml-2">
                                      {user.lab_name}
                                    </p>
                                  </div>
                                  <div className="cart__priceContainer mt-2">
                                    <p className="cart__title">Type:</p>
                                    <p className="cart__price ml-2">
                                      {user.type}
                                    </p>
                                  </div>
                                  {/* <p className="cart__title">{user.lab_name}</p> */}
                                  {/* </div> */}
                                  <div className="cart__editable row mt-4">
                                    <div className="col-12 col-lg-4">
                                      <div
                                        className="form-group "
                                        style={{ position: "relative" }}
                                      >
                                        <label className="semiFont login__label mb-1">
                                          Choose Member {required}
                                        </label>
                                        <Select
                                          value={user.member_id}
                                          id="inputDropdown"
                                          isSearchable={false}
                                          placeholder="Select Member ID"
                                          options={memberOptions}
                                          isDisabled={
                                            user.lab_status &&
                                            user.product_status
                                              ? false
                                              : true
                                          }
                                          onChange={(v) => {
                                            formik.setFieldValue(
                                              `userdata[${x}].member_id`,
                                              v
                                            );
                                            // let value = [...cartItems];
                                            // value[i].member_id = v.value;
                                            // setCartItems(value);
                                          }}
                                          styles={{
                                            control: (base, state) => ({
                                              ...base,
                                              marginTop: "5px",
                                              padding: "8px",

                                              //   border:
                                              //     formik.errors &&
                                              //     formik.errors.userdata &&
                                              //     formik.errors.userdata[x].member_id
                                              //       ? "1px solid red"
                                              //       : "1px solid #c3cad9",
                                              border:
                                                user.lab_status &&
                                                user.product_status &&
                                                Object.keys(formik.errors)
                                                  .length
                                                  ? formik.errors.userdata[x] &&
                                                    formik.errors.userdata[x]
                                                      .member_id.value
                                                    ? "1px solid red"
                                                    : "1px solid #c3cad9"
                                                  : "1px solid #c3cad9",

                                              "&:active": {
                                                outline: "none",
                                                boxShadow: "none",
                                              },
                                              "&:hover": {
                                                outline: "none",
                                                boxShadow: "none",
                                              },
                                            }),
                                            option: (base, state) => ({
                                              ...base,
                                              backgroundColor: state.isFocused
                                                ? "#eee"
                                                : "#fff",
                                              padding: "8px",
                                              display: "flex",
                                              fontFamily: "semiFont",
                                              alignItems: "center",
                                              fontSize: "16px",
                                              zIndex: 9999,
                                              color: state.isFocused
                                                ? "#183b56"
                                                : "#183b56",
                                              "&:hover": {
                                                backgroundColor: state.isFocused
                                                  ? "#eee"
                                                  : "#fff",
                                              },
                                            }),
                                          }}
                                          className="admin__Addproduct--dropdown "
                                        />
                                        {user.lab_status &&
                                        user.product_status &&
                                        Object.keys(formik.errors).length
                                          ? formik.errors.userdata[x] && (
                                              <div className="err">
                                                <p>
                                                  {
                                                    formik.errors.userdata[x]
                                                      .member_id.value
                                                  }
                                                </p>
                                              </div>
                                            )
                                          : null}
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                      <div
                                        className="inputBox"
                                        style={{
                                          position: "relative",
                                          marginTop: "-3px",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <label className="semiFont login__label">
                                          {user.type === "KIT DELIVERY"
                                            ? "Delivery Date"
                                            : "Appointment"}
                                          {required}
                                        </label>

                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <DatePicker
                                            autoOk
                                            variant="inline"
                                            format="DD-MM-YYYY"
                                            disablePast={true}
                                            placeholder="Select Appointment"
                                            value={moment(
                                              user.appointment
                                            ).format("YYYY-MM-DD")}
                                            disabled={
                                              user.lab_status &&
                                              user.product_status
                                                ? false
                                                : true
                                            }
                                            onChange={(date) => {
                                              formik.setFieldValue(
                                                `userdata[${x}].appointment`,
                                                moment(date).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );

                                              //   console.log("date", date._d);
                                            }}
                                            style={{
                                              width: "auto !important",
                                            }}
                                            animateYearScrolling
                                          />
                                          {user.lab_status &&
                                          user.product_status &&
                                          Object.keys(formik.errors).length
                                            ? formik.errors.userdata[x] && (
                                                <div className="err">
                                                  <p>
                                                    {
                                                      formik.errors.userdata[x]
                                                        .appointment
                                                    }
                                                  </p>
                                                </div>
                                              )
                                            : null}
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}

                          <div className="userList-card positioned__cartBtn">
                            <div className="cart__detailsSection">
                              {/* <div className="cart__detailsSect"> */}
                              <div className="cart__items--column">
                                <div className="cart__priceContainer mt-2">
                                  <p className="cart__title">Cart Items:</p>
                                  <p className="cart__price ml-2">
                                    {cartItems.length}
                                  </p>
                                </div>
                                <div className="cart__priceContainer mt-2">
                                  <p className="cart__title">Cart Total:</p>
                                  <p className="cart__price ml-2">
                                    <IconContext.Provider
                                      value={{
                                        color: "#616161",
                                        size: "20px",
                                        //   style: { marginBottom: "3px" },
                                      }}
                                    >
                                      <BiRupee />
                                    </IconContext.Provider>
                                    {sum}
                                  </p>
                                </div>
                              </div>

                              <Button
                                variant="contained"
                                onClick={() => {
                                  const valid = checkAvailable(formik.values);
                                  if (valid) {
                                    // formik.validateform();
                                    // formik.setSubmitting(true);
                                    formik.handleSubmit();
                                  }
                                }}
                                disabled={submitLoading}
                                style={{
                                  padding: submitLoading
                                    ? "15px 100px"
                                    : "15px 30px",
                                }}
                                className="user-proceed-button"
                              >
                                {submitLoading ? (
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  "Proceed to Checkout"
                                )}
                              </Button>
                            </div>
                            {/* </div> */}

                            {/* <div className="d-flex flex-row justify-content-end"></div> */}
                          </div>
                        </>
                      ) : (
                        <div
                          className="center__items p-5"
                          style={{ flexDirection: "column" }}
                        >
                          <img src={emptyCart} alt="Empty_cart" />
                          <p className="header__txt mb-2">Your cart is empty</p>

                          <Button
                            variant="contained"
                            className="primary__btn"
                            id="admin-reportview-download-btn"
                            style={{
                              padding: "10px 25px",

                              color: "#fff",
                            }}
                            onClick={() => history.push("/user/book")}
                          >
                            Book Now
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                );
              }}
            </Formik>

            {/* <div className="d-flex flex-row justify-content-end"></div> */}

            {/* ) : (
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    marginTop: "20px",
                  }}
                >
                  <IconContext.Provider
                    value={{ color: "#D2D2D2", size: "100px" }}
                  >
                    <FaCartPlus />
                  </IconContext.Provider>
                  <p
                    style={{
                      color: "#D2D2D2",
                      padding: "20px",
                      fontSize: "20px",
                      fontFamily: "mediumFont",
                    }}
                  >
                    Cart is empty...
                  </p>
                </div>
              )} */}
            {/* </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default Cart;
