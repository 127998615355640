import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/users.css";
import "bootstrap/dist/css/bootstrap.min.css";

import moment from "moment";
import {
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoMdArrowDropright,
} from "react-icons/io";
import { HiOutlineDocumentText } from "react-icons/hi";
import { BiEditAlt } from "react-icons/bi";
import { MdLocalHospital } from "react-icons/md";
import TablePagination from "@material-ui/core/TablePagination";
import Global from "../global";
import { IoSearchOutline } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { MdAdd } from "react-icons/md";

import { IconContext } from "react-icons";
import Select from "react-select";
import { MdKeyboardBackspace } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
// import API from "../utills/api";
import Loader from "react-loader-spinner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useLocation } from "react-router";
import emptyReport from "../assets/images/empty_report.png";

const useStyles = makeStyles({
  rootAbsolute: {
    position: "relative",
    fontFamily: "BoldFont",

    "& .MuiButton-startIcon": {
      position: "absolute",
      right: 0,
    },
    "& .MuiButton-label": {
      marginRight: 10,
      transition: "all 0.3s",
      "&:hover": {
        marginRight: 30,
      },
    },
  },
});

const Reports = (props) => {
  const history = useHistory();
  const location = useLocation();
  console.log("location", location);
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
  // console.log("AdminProduct", props);
  const [open, setOpen] = useState(false);

  //   const [period, setPeriod] = useState();
  const [status, setStatus] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [allreports, setallReports] = useState([]);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log("loading", loading);
  const [refresh, setRefresh] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const [editData, setEdit] = useState({
    data: {},
    index: "",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    const total = allreports.length;
    let startIndex = (newPage + 1) * rowsPerPage - rowsPerPage;

    const endIndex = startIndex + rowsPerPage;
    const data = allreports.slice(startIndex, endIndex);

    setReports([...data]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    let startIndex = 1 * event.target.value - event.target.value;

    const endIndex = startIndex + event.target.value;
    const data = allreports.slice(startIndex, endIndex);

    setReports([...data]);
  };

  useEffect(() => {
    async function fetchreports() {
      try {
        const response = await axios.post(
          Global.base_url + "/user/report",
          null,
          config
        );
        // console.log(response);

        if (response.status === 200) {
          console.log("reports", response);
          setLoading(false);
          let datatest = response.data.data;
          const total = datatest.length;
          let startIndex = (page + 1) * rowsPerPage - rowsPerPage;

          const endIndex = startIndex + rowsPerPage;
          const data = datatest.slice(startIndex, endIndex);
          // console.log("products response: ", response);
          setallReports(response.data.data);
          setReports(data);
        }
      } catch (error) {
        // console.log("errror", error);
        if (error.response) {
          if (error.response.status === 401) {
            // console.log("admin product error", error);
            setLoading(true);
            return toast.error("Unable to get reports");
          }
          if (error.response.status === 500) {
            // console.log("admin product error", error);
            setLoading(true);
            return toast.error("Internal server error");
          }
        }
      }
    }
    fetchreports();
  }, [refresh]);
  // const classfilter=open?"d-flex flex-column "
  const rowdataAlign = { verticalAlign: "middle" };
  editData.data = reports[editData.index];

  return (
    <>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div className="report__container">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            // newestOnTop={false}
            closeOnClick
            rtl={false}
          />

          <section className="filter-section testreport">
            <div className="sectionHeader p-3">
              <h2 className="hkBoldFont" style={{ color: "#161616" }}>
                Reports
              </h2>
              <p className="mediumFont mt-2" style={{ color: "#515151" }}>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end  flex-md-row  mr-0 pt-2 px-0 pb-0">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  //   border: showFilter ? "1px solid #c3cad9" : 0,
                  borderLeft: "none",
                  borderBottom: "none",
                }}
                className="search__testcontainer"
                // id={open ? "searchTransition" : "searchStatic"}
              >
                <input
                  type="text"
                  name="search"
                  id={"search__input"}
                  placeholder="Search Report"
                />
              </div>
              {/* <Button
                variant="contained"
                color="secondary"
                className="primary__button"
                type="submit"
                startIcon={
                  <IconContext.Provider
                    value={{
                      color: "#fff",
                      size: "25px",
                    }}
                  >
                    <MdAdd />
                  </IconContext.Provider>
                }
                style={{ width: "250px" }}
                className={[classes.rootAbsolute, "primary__button"]}
                onClick={() => {
                  setEdit({
                    data: "",
                    index: "",
                  });
                  setShowEditModal(true);
                }}
              >
                Add Member
              </Button> */}
            </div>
          </section>
          <section
            className="admin-product-filter-section table-responsive fixHeader
"
          >
            <table
              className="table  text-center  admin-product_usertable m-0"
              style={{
                backgroundColor: "white",
              }}
              id="bill-table"
            >
              <thead>
                <tr
                  id="admin-product-row"
                  style={{ border: "none", backgroundColor: "#E0E0E0" }}
                >
                  <th scope="col" className="admin-product-row_table-header">
                    Report ID
                  </th>
                  <th scope="col" className="admin-product-row-table-header">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Package / Test
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-price-col"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Lab
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-action-col admin-product-row-table-header"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {reports.length > 0 ? (
                  reports.map((data, i) => {
                    if (i > rowsPerPage - 1) {
                      return;
                    }
                    const color =
                      data.status === "Completed"
                        ? "#03A33B"
                        : data.status === "Awaiting Confirmation"
                        ? "red"
                        : data.status === "Scheduled" ||
                          data.status === "Rescheduled"
                        ? "#4059E2"
                        : data.status === "Cancelled"
                        ? "#818181"
                        : "#EFA500";

                    //   const borderLeft = `5px solid ${color}`;
                    const borderBottom = `1px solid #B3CDFF`;
                    // const font = "mediumFont";

                    return (
                      <>
                        <tr
                          key={data.report_id + Math.random()}
                          style={{
                            //   borderLeft,
                            borderBottom,
                            backgroundColor: "white",
                          }}
                          className="admin-product-row-table-row-hover "
                        >
                          <td
                            scope="row"
                            id="admin-product-rd"
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            <p
                              data-toggle="collapse"
                              data-target={`#accordion` + i}
                              className="clickable"
                              id="button-open"
                              // style={{ display: "none" }}
                            >
                              <IconContext.Provider
                                value={{
                                  color: "#4059E2",
                                  size: "25px",
                                  style: { marginBottom: "3px" },
                                }}
                              >
                                <IoMdAddCircle className="toggle-row-icon" />
                              </IconContext.Provider>
                            </p>{" "}
                            RID{data.report_id}
                          </td>
                          <td
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            {" "}
                            {data.member_name}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.package_name
                              ? data.package_name
                              : data.test_name}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.type}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{ ...rowdataAlign }}
                          >
                            {data.lab_name}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{ ...rowdataAlign, color: color }}
                          >
                            {data.status}
                          </td>
                          <td
                            className="admin-product-row-action-col admin-product-row-table-row-data "
                            // colSpan={1}
                            style={rowdataAlign}
                          >
                            <Button
                              key={"view" + i}
                              variant="contained"
                              color="default"
                              className={"admin-product-Filter__button mr-2"}
                              // startIcon={<AiOutlineCloudDownload color="#2C3E50" />}
                              style={{ padding: "10px 20px" }}
                              onClick={() => {
                                // toast.success("Hello");

                                history.push({
                                  pathname: "/user/report-view",
                                  state: {
                                    data: data,
                                    location: location.pathname,
                                  },
                                });
                              }}
                              startIcon={<HiOutlineDocumentText />}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                        <tr
                          id={"accordion" + i}
                          className="collapse  admin-product-hided-row"
                          key={data.report_id + Math.random()}
                        >
                          <td colSpan="6" style={rowdataAlign}>
                            <ul className="admin-product-row-table-hide-list text-left ">
                              <li
                                key={data.report_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Package / Test :
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.package_name || data.test_name}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={i + data.report_id + i + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Type :
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.type}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={data.report_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Lab :
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    // style={{ color }}
                                  >
                                    {data.lab_name}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={data.report_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Status :
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{ color }}
                                  >
                                    {data.status}
                                  </div>
                                </div>
                              </li>

                              <li
                                key={data.report_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-center mt-2 ml-3">
                                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center">
                                    <Button
                                      key={i}
                                      variant="contained"
                                      color="default"
                                      className={
                                        "admin-product-Filter__button mr-2"
                                      }
                                      // startIcon={
                                      //   <AiOutlineCloudDownload color="#2C3E50" />
                                      // }
                                      style={{ padding: "10px 33px" }}
                                      onClick={() => {
                                        history.push({
                                          pathname: "/user/report-view",
                                          state: {
                                            data: data,
                                            location: location.pathname,
                                          },
                                        });
                                      }}
                                      startIcon={<HiOutlineDocumentText />}
                                      // onClick={() => {
                                      //   setBillView({ isView: true, data });
                                      // }}
                                    >
                                      View
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  // <tr>
                  //   <td colSpan={6}>
                  //     {" "}
                  <div
                    className="center__items p-5"
                    style={{ flexDirection: "column" }}
                  >
                    <img src={emptyReport} alt="Empty Reports" />
                    <p
                      className="header__txt mt-2"
                      style={{ fontSize: "20px" }}
                    >
                      Reports not found
                    </p>
                  </div>
                  //   </td>
                  // </tr>
                )}
              </tbody>
            </table>
          </section>
          {allreports.length > 5 ? (
            <div className="d-flex flex-row align-items-center justify-content-end mb-4">
              <TablePagination
                component="div"
                count={allreports.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 50]}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Reports;
