import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LoginPage from "./components/LoginPage";
import Forget from "./components/Forget";
import SignUp from "./components/SignUp";
import Sidebar from "./components/Sidebar";
import { GlobalProvider } from "./context/Context";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1565d8",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        color: "#fff",
        backgroundColor: "#444",
      },
    },
  },
  typography: {
    fontFamily: `semiFont`,
    // fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

function App() {
  return (
    <GlobalProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/forget-password" component={Forget} />
            <Route exact path="/signup" component={SignUp} />

            <Route path="/user" component={Sidebar} />
            {/* <Route exact path="/forget-password" component={ForgetPassword} />

          <Route path="/student" component={Sidebar} />
          <Route path="/zoom" component={Zoom} />
          <Route path="/videoplay" component={VideoPlay} /> */}
            <Redirect from="/login" to="/" />
          </Switch>
        </Router>
      </ThemeProvider>
    </GlobalProvider>
  );
}

export default App;
