import { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "../assets/css/home.css";
import Banner from "../assets/images/banner.jfif";
import Completed from "../assets/images/completed.png";
import Inprogress from "../assets/images/inprogress.png";
import TotalTest from "../assets/images/total_test.png";
import axios from "axios";
import Global from "../global";
import Loader from "react-loader-spinner";
import { IconContext } from "react-icons";
import { BsThreeDots } from "react-icons/bs";
import moment from "moment";
import emptyAppointment from "../assets/images/empty_appointment.png";

const Home = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState({});

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  useEffect(() => {
    async function fetchHomeData() {
      try {
        const response = await axios.post(
          Global.base_url + "/user/dashboard",
          null,
          config
        );
        // console.log(response);

        if (response.status === 200) {
          setLoading(false);
          setItems(response.data);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            return toast.error("Unable to get data");
          }
          if (error.response.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
    }

    fetchHomeData();
  }, []);

  var date1, today;

  const calculateDays = (date) => {
    date1 = moment(date, "YYYY-MM-DD");

    today = moment();
    if (date1 >= today)
      return (
        Math.abs(today.startOf("day").diff(date1.startOf("day"), "days")) +
        " Days"
      );
    else return "expired";
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div className="home__container">
          {/* home container */}
          <div className="home__banner">
            <img
              src={Banner}
              alt="Home__bannerimage"
              className="home__bannerImage"
            />
            <div className="home__bannerBtn">
              <Button
                color="primary"
                variant="contained"
                id="signin"
                onClick={() => history.push("/user/book")}
                className={"banner__btn"}
              >
                Book now
              </Button>
            </div>
          </div>
          {/* status and reports section */}
          {items ? (
            <div className="home__reportContainer mt-3">
              <div className="status__container">
                <div className="status__itemsConatiner">
                  <p className="status__title">Completed</p>
                  <div className="status__detailsRow">
                    <p className="status__count">{items.completed}</p>
                    <img src={Completed} alt="Test completed" />
                  </div>
                </div>
                <div className="status__itemsConatiner">
                  <p className="status__title">In Progress</p>
                  <div className="status__detailsRow">
                    <p className="status__count">{items.inProgess}</p>
                    <img src={Inprogress} alt="Test inprogress" />
                  </div>
                </div>
                <div className="status__itemsConatiner">
                  <p className="status__title">Total Tests</p>
                  <div className="status__detailsRow">
                    <p className="status__count">{items.totalTest}</p>
                    <img src={TotalTest} alt="Total test" />
                  </div>
                </div>
              </div>
              <div className="reports__container">
                <div className="report__header mx-2 mt-2">
                  <p>Appointments</p>
                  {/* <IconContext.Provider
                    value={{
                      color: "#2e2a60",
                      size: "25px",
                    }}
                  >
                    <BsThreeDots />
                  </IconContext.Provider> */}
                </div>
                <div className="reports__details p-2 mt-2">
                  <p className="report__headerTitle name">Name</p>
                  <p className="report__headerTitle package">Package / Test</p>
                  <p className="report__headerTitle days">Days to Test</p>
                </div>
                {items.appointment && items.appointment.length ? (
                  items.appointment.map((item, i) => {
                    return (
                      <div
                        className="reports__details-data"
                        key={i}
                        onClick={() => {
                          history.push({
                            pathname: "/user/report-view",
                            state: {
                              data: item,
                              location: location.pathname,
                            },
                          });
                        }}
                      >
                        <p className="report__data name">
                          {item.member_name}
                          {/* hello */}
                        </p>
                        <p className="report__data package">
                          {item.package_name || item.test_name}
                          {/* test data */}
                        </p>
                        <p className="report__data days">
                          {calculateDays(item.appointment)}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="center__items p-5"
                    style={{ flexDirection: "column" }}
                  >
                    <img src={emptyAppointment} alt="Empty appointment" />
                    <p
                      className="header__txt mt-2"
                      style={{ fontSize: "20px" }}
                    >
                      Appointments not found
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Home;
