const config = {
  headers: {
    Authorization: `Bearer "${localStorage.getItem("token")}"`,
  },
};
const config2 = {
  headers: {
    Authorization: `Bearer "${localStorage.getItem("token")}"`,
    "Content-Type": "application/json",
  },
};

module.exports = {
  token: localStorage.getItem("token"),
  // base_url: "http://192.168.1.12:4000/api",
  base_url: "https://api.healthmo.ai/api",
  headers: { Accept: "*/*", "Content-type": "application/json; charset=UTF-8" },
  user_auth: "",
  config,
  config2,
};
