// export default Forget;
import React, { useEffect, useState } from "react";
import "../assets/css/login.css";
import { Formik } from "formik";
import LoginSideContent from "./LoginSideContent";
import LoginLogo from "../assets/images/ampath.jpg";
import { FaArrowLeft } from "react-icons/fa";
import * as yup from "yup";
import axios from "axios";
import Global from "../global";
import { Button } from "@material-ui/core";
import _ from "lodash";
// import "../assets/css/forgetpwd.css";
import { Link, useHistory } from "react-router-dom";
import { data } from "jquery";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineClose,
} from "react-icons/ai";
import { MdKeyboardBackspace } from "react-icons/md";
import { VscEyeClosed, VscEye } from "react-icons/vsc";

import { IconContext } from "react-icons";
import { toast, ToastContainer } from "react-toastify";
const GetEmail = ({ stepChange, data }) => {
  // const []
  const [loading, setLoading] = useState(false);
  return (
    <Formik
      initialValues={{
        email_id: "",
      }}
      validationSchema={yup.object({
        email_id: yup.string().email().required("Email cannot be empty"),
      })}
      onSubmit={async (values, formik) => {
        // console.log(values);
        setLoading(true);
        try {
          const response = await axios.post(
            Global.base_url + "/user/forget",
            {
              email: values.email_id,
            },
            Global.headers
          );
          if (response.status === 200) {
            console.log("response", response);
            data({
              email_id: values.email_id,
              key: "",
            });
            return stepChange(1);
          }
        } catch (error) {
          console.log(error);
          if (error.response && error.response.status === 400) {
            setLoading(false);
            return formik.setFieldError("email_id", "Email not verified");
          }
        }
      }}
    >
      {(formik) => {
        return (
          <>
            {/* <p
              className="create__accText"
              style={{ fontSize: "18px", marginTop: "15px" }}
            >
              {" "}
              Enter the email associate with your account{" "}
            </p> */}
            {/* <div className="inputBox"> */}
            <div className="inputBox " style={{ position: "relative" }}>
              <label htmlFor="email" className="semiFont login__label">
                Email
              </label>
              <input
                type="email"
                //   className="form-control signup__input"
                id="email_id"
                placeholder="xyz@email.com"
                {...formik.getFieldProps("email_id")}
                style={{
                  border: formik.errors.email_id
                    ? "1px solid red"
                    : "1px solid #c3cad9",
                  marginTop: "5px",
                  //   width: "auto",
                }}
              />
              {formik.errors.email_id && (
                <div className="err">
                  <p>{formik.errors.email_id}</p>
                </div>
              )}
            </div>
            <div
              className="center__items mt-2"
              style={{ flexDirection: "column" }}
            >
              <Button
                color="primary"
                variant="contained"
                id="signin"
                onClick={formik.handleSubmit}
                // className={"signup__button"}
                style={{
                  backgroundColor: "#1565d8",
                  padding: "10px 60px",
                  marginTop: "10px",
                  textTransform: "inherit",
                }}
                disabled={loading}
              >
                {loading ? (
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const OTP = ({ stepChange, data, setData }) => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [show, setShow] = useState(false);
  async function sendAgain() {
    setLoading1(true);
    try {
      const response = await axios.post(
        Global.base_url + "/user/forget",
        {
          email: data.email_id,
        },
        Global.headers
      );
      if (response.status === 200) {
        setLoading1(false);
        setShow(true);
        let timer = setInterval(() => {
          setShow(false);
          clearInterval(timer);
        }, 5000);
        return;
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        toast.success("Invalid user");
        return setLoading1(false);
      }
    }
  }
  // console.log(data);
  return (
    <Formik
      initialValues={{
        otp: "",
      }}
      validationSchema={yup.object({
        otp: yup.string().required("Verification code cannot be empty"),
      })}
      onSubmit={async (values, formik) => {
        // console.log(values, data);
        setLoading(true);
        let val = {
          ...values,
          email: data.email_id,
        };

        try {
          const response = await axios.post(
            Global.base_url + "/user/forget/forget_verify",
            val,
            Global.headers
          );

          // console.log(response);

          if (response.status === 200) {
            setData({
              ...data,
              key: response.headers.resetkey,
            });
            return stepChange(2);
          }
        } catch (error) {
          console.log(error);
          if (error.response && error.response.status === 403) {
            setLoading(false);
            return formik.setFieldError("otp", "Email is not verified");
          }
        }
      }}
    >
      {(formik) => {
        return (
          <>
            {show ? (
              <p
                style={{
                  color: "#03A33B",
                  fontFamily: "mediumFont",
                }}
              >
                Message sent
              </p>
            ) : null}
            {/* <div className="inputBox"> */}
            <div className="inputBox " style={{ position: "relative" }}>
              <label htmlFor="otp" className="semiFont login__label">
                Verification Code
              </label>
              <input
                type="otp"
                id="otp"
                placeholder="Enter OTP"
                value={formik.values.otp}
                style={{
                  border: formik.errors.otp
                    ? "1px solid red"
                    : "1px solid #c3cad9",
                  marginTop: "5px",
                }}
                onChange={(e) =>
                  formik.setFieldValue(
                    "otp",
                    e.currentTarget.value.replace(/[^0-9]/g, "")
                  )
                }
              />
              {formik.errors.otp && (
                <div className="err">
                  <p>{formik.errors.otp}</p>
                </div>
              )}
            </div>

            <div
              className="center__items mt-2"
              style={{ flexDirection: "column" }}
            >
              <Button
                variant="contained"
                color="primary"
                id="signin"
                // className={"signup__button"}
                onClick={formik.handleSubmit}
                style={{
                  backgroundColor: "#1565d8",
                  padding: "10px 60px",
                  marginTop: "10px",
                  textTransform: "initial",
                }}
                disabled={loading}
              >
                {loading ? (
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
            <div className="mt-3 text-center create__accText semiFont">
              Didn’t get email?
              {/* <Link className="signup__link">Send again</Link> or{" "} */}
              <Button
                color="primary"
                onClick={sendAgain}
                style={{
                  color: "#1565d8",
                  textTransform: "initial",
                  fontSize: "16px",
                  marginTop: "-4px",
                }}
              >
                {loading1 ? (
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Send Again"
                )}
              </Button>
              or
              <Button
                color="primary"
                style={{
                  color: "#1565d8",
                  textTransform: "initial",
                  fontSize: "16px",
                  marginTop: "-4px",
                }}
                onClick={() => {
                  stepChange(0);
                }}
              >
                Change Email
              </Button>
              {/* <Link className="signup__link" onClick={handleBack}>Change email</Link> */}
            </div>
            {/* </div> */}
          </>
        );
      }}
    </Formik>
  );
};

const ChangePwd = ({ stepChange, data, setData, close }) => {
  const [loading, setLoading] = useState(false);
  const [securetext, setSecuretext] = useState(true);
  const [securetext2, setSecuretext2] = useState(true);
  const history = useHistory();
  // console.log(data);
  return (
    <Formik
      initialValues={{
        newpassword: "",
        confirmpwd: "",
      }}
      validationSchema={yup.object({
        newpassword: yup
          .string()
          .required("Password cannot be empty")
          .min(8, "password must have aleast 8 letters ")
          .notOneOf(
            ["password", "PASSWORD"],
            "Password does not contain 'password'"
          ),
        confirmpwd: yup
          .string()
          .when("newpassword", (newpassword) => {
            if (newpassword)
              return yup
                .string()
                .oneOf([yup.ref("newpassword")], " password must be same")
                .notOneOf(["password"], "password must not be 'password'")
                .min(8)
                .max(15)
                .required("confirm password cannot be empty");
          })
          .required("confirm password cannot be empty"),
      })}
      onSubmit={async (values, formik) => {
        // console.log(values, data);

        setLoading(true);
        let val = {
          ...values,
          email: data.email_id,
          resetKey: data.key,
        };

        try {
          const response = await axios.post(
            Global.base_url + "/user/forget/forget_change",
            val,
            Global.headers
          );

          // console.log(response);

          if (response.status === 200) {
            // console.log("success");
            return history.replace({
              pathname: "/",
              success: { isForget: true },
            });
          }
        } catch (error) {
          console.log(error);
          if (error.response && error.response.status === 401) {
            setLoading(false);
            return formik.setFieldError(
              "newpassword",
              "password is not changed"
            );
          }
          if (error.response && error.response.status === 422) {
            setLoading(false);
            // return formik.setFieldError("newpassword", error.response.data);
          }
        }
      }}
    >
      {(formik) => {
        return (
          <>
            <div className="inputBox " style={{ position: "relative" }}>
              <label htmlFor="newpassword" className="semiFont login__label">
                New Password
              </label>
              <input
                type={securetext ? "password" : "text"}
                value={formik.values.newpassword}
                onChange={(e) => {
                  formik.setFieldValue(
                    "newpassword",
                    e.currentTarget.value.replace(/[^a-zA-Z0-9@]/g, "")
                  );
                }}
                style={{
                  border: formik.errors.newpassword
                    ? "1px solid red"
                    : "1px solid #c3cad9",
                }}
                id="newpassword"
                placeholder="New password"
              />
              <div
                className="password__icon"
                onClick={() =>
                  // Shows password when clicking eye icon
                  securetext ? setSecuretext(false) : setSecuretext(true)
                }
              >
                <IconContext.Provider
                  value={{
                    color: "#959EAD",
                    size: "20px",
                  }}
                >
                  {securetext ? <VscEye /> : <VscEyeClosed />}
                </IconContext.Provider>
              </div>

              {formik.errors.newpassword && (
                <div className="err">
                  <p>{formik.errors.newpassword}</p>
                </div>
              )}
            </div>
            <div
              className="inputBox"
              style={{ position: "relative" }}
              id="a123"
            >
              <label
                htmlFor="confirmpassword"
                className="semiFont login__label"
              >
                Confirm Password
              </label>
              <input
                type={securetext2 ? "password" : "text"}
                value={formik.values.confirmpwd}
                onChange={formik.handleChange("confirmpwd")}
                style={{
                  border: formik.errors.confirmpwd
                    ? "1px solid red"
                    : "1px solid #c3cad9",
                }}
                id="confirmpassword"
                placeholder="Confirm password"
              />
              <div
                className="password__icon"
                onClick={() =>
                  // Shows password when clicking eye icon
                  securetext2 ? setSecuretext2(false) : setSecuretext2(true)
                }
              >
                <IconContext.Provider
                  value={{
                    color: "#959EAD",
                    size: "20px",
                  }}
                >
                  {securetext2 ? <VscEye /> : <VscEyeClosed />}
                </IconContext.Provider>
              </div>

              {formik.errors.confirmpwd && (
                <div className="err">
                  <p>{formik.errors.confirmpwd}</p>
                </div>
              )}
            </div>

            <div
              className="center__items mt-2"
              style={{ flexDirection: "column" }}
            >
              <Button
                variant="contained"
                color="primary"
                id="signin"
                // className={"signup__button"}
                onClick={formik.handleSubmit}
                style={{
                  backgroundColor: "#1565d8",
                  padding: "10px 30px",
                  marginTop: "20px",
                  width: "auto",
                }}
                disabled={loading}
              >
                {loading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Change Password"
                )}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const ForgetPassword = ({ close }) => {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    email_id: "",
    key: "",
  });
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <section className="login__container">
        <div className="login__section">
          <div className="login__elements">
            <img src={LoginLogo} alt="Ampath logo" className="login__logo" />
            <div className="forget__header" style={{ marginTop: "15px" }}>
              <div style={{ cursor: "pointer", marginTop: "-5px" }}>
                <IconContext.Provider
                  value={{
                    color: "#616161",
                    size: "25px",
                    cursor: "pointer",
                  }}
                >
                  <FaArrowLeft onClick={() => history.push("/")} />
                </IconContext.Provider>
              </div>
              <h2
                className="hkBoldFont login__header"
                style={{ marginLeft: "10px" }}
              >
                Forget Password
              </h2>
            </div>
            {/* <h3
                  style={{ fontSize: "20px", color: "#616161" }}
                  className="boldFont"
                >
                  Enter the email associate with your account
                </h3> */}
            {/* <div className="login__main "> */}
            {step === 0 && <GetEmail stepChange={setStep} data={setData} />}
            {step === 1 && (
              <OTP stepChange={setStep} setData={setData} data={data} />
            )}
            {step === 2 && (
              <ChangePwd
                stepChange={setStep}
                setData={setData}
                data={data}
                close={close}
              />
            )}
            {/* </div> */}
          </div>
        </div>
        <LoginSideContent />
      </section>
    </>
  );
};

export default ForgetPassword;
