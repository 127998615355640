import React, { useEffect, useState } from "react";
import { BiRupee } from "react-icons/bi";
import { GiWorld } from "react-icons/gi";
import { FiDollarSign } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import "../assets/css/book.css";
import Select, { components } from "react-select";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
import NumericInput from "react-numeric-input";
import { Button } from "@material-ui/core";
import axios from "axios";
import Global from "../global";
import { useHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import { toast, ToastContainer } from "react-toastify";
import { FiArrowLeft } from "react-icons/fi";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import Tooltip from "@material-ui/core/Tooltip";
import { IoSearchOutline } from "react-icons/io5";
import emptyProduct from "../assets/images/empty_product.png";

// import Header from "./Header";
const data = [
  {
    id: 1,
    title: "RT-PCR Test",
    oldAmount: 750,
    newAmount: 600,
  },
  {
    id: 2,
    title: "IgM Antibody Test",
    oldAmount: 750,
    newAmount: 600,
  },
  {
    id: 3,
    title: "IgA Antibody Test",
    oldAmount: 750,
    newAmount: 600,
  },
  {
    id: 4,
    title: "nAB Test",
    oldAmount: 750,
    newAmount: 600,
  },
];

var priceArr = [];
var totalAmount = 0;
function Book(props) {
  const history = useHistory();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
  //header dropdown options
  const options = [
    { value: "INDIA", label: "INDIA" },
    { value: "USA", label: "USA" },
  ];
  //   const testOptions = [
  //     { value: "Walk In", label: "Walk In" },
  //     { value: "Home Test", label: "Home Test" },
  //     { value: "Kit Delivery", label: "Kit Delivery" },
  //   ];
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [hospitals, setHospitals] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedType, selectType] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedState, selectState] = useState(null);
  const [selectedCity, selectCity] = useState(null);
  const [selectedHospital, selectHospital] = useState(null);
  const [testOptions, setTestOptions] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [tests, setTests] = useState([]);
  useEffect(() => {
    async function getProductType() {
      try {
        const result = await axios.post(
          Global.base_url + "/user/book/types",
          null,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        // console.log(result);
        if (result.status === 200) {
          console.log("product response", result);
          let products = result.data.data.map((item) => {
            return {
              label: item.product_type,
              value: item.product_type,
            };
          });
          setTestOptions(products);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getProductType();
  }, []);
  const getStates = async (type) => {
    console.log("type", type);
    priceArr = [];
    setCheckedList([]);
    selectState(null);
    selectCity(null);
    selectHospital(null);
    setStates(null);
    setCities(null);
    setHospitals(null);
    setProducts([]);

    selectType(type);
    // console.log(type);

    try {
      const result = await axios.post(
        Global.base_url + "/user/book/states",
        { type: type.value },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(result);
      if (result.status === 200) {
        console.log("state options", result);
        let options = result.data.data.map((data) => {
          return { label: data.state, value: data.state };
        });
        setStates(options);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCities = async (state) => {
    priceArr = [];
    setCheckedList([]);
    selectCity(null);
    selectHospital(null);

    setCities(null);
    setHospitals(null);
    setProducts([]);
    selectState(state);
    try {
      const result = await axios.post(
        Global.base_url + "/user/book/cities",
        { state: state.value, type: selectedType.value },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(result);
      if (result.status === 200) {
        console.log("city options", result);
        let options = result.data.data.map((data) => {
          return { label: data.city, value: data.city };
        });
        setCities(options);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getHospitals = async (city) => {
    priceArr = [];
    setCheckedList([]);
    selectHospital(null);

    setHospitals(null);
    setProducts([]);

    selectCity(city);
    try {
      const result = await axios.post(
        Global.base_url + "/user/book/labs",
        {
          state: selectedState.value,
          city: city.value,
          type: selectedType.value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(result);
      if (result.status === 200) {
        console.log("lab options", result);
        let options = result.data.data.map((data) => {
          return { label: data.lab_name, value: data.lab_id };
        });
        setHospitals(options);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProducts = async (hospital) => {
    priceArr = [];
    setCheckedList([]);
    selectHospital(hospital);
    try {
      const result = await axios.post(
        Global.base_url + "/user/book/lab/products",
        { lab_id: hospital.value, type: selectedType.value },
        config
      );
      // console.log(result);
      if (result.status === 200) {
        console.log("product list", result);
        // let options = result.data.data.map((data) => {
        //   return { label: data.hospital_name, value: data.hospital_id };
        // });

        let data = result.data.packages.filter(
          (p) => p.package_type === selectedType.value
        );
        let testResult = result.data.test.filter(
          (p) => p.test_type === selectedType.value
        );

        let filteredPackage = data.map((item) => {
          return {
            package_id: item.package_id,
            package_name: item.package_name,
            pack_recommend: item.pack_recommend,
            package_desc: item.package_desc,
            pack_offer_text: item.pack_offer_text,
            pack_linked: item.pack_linked,
            test_inclusion: item.test_inclusion,
            package_price: item.package_price,
            pack_offer_price: item.pack_offer_price,
            package_type: item.package_type,
            testData: item.testData,
            itemType: "Package",
          };
        });

        let filteredTest = testResult.map((item) => {
          return {
            test_id: item.test_id,
            package_name: item.test_name,
            pack_recommend: item.recommended_for,
            package_desc: item.test_description,
            pack_offer_text: item.offer_text,
            pack_linked: null,
            test_inclusion: null,
            package_price: item.price,
            pack_offer_price: item.offer_price,
            package_type: item.test_type,
            testData: [],
            itemType: "Test",
          };
        });

        let overAllData = [...filteredPackage, ...filteredTest];

        let sortedItems = overAllData.map((item, i) => {
          // if (item.package_type === selectedType.value) {
          return { ...item, product_id: i };
          // }
        });

        console.log("sortedPackage", sortedItems);

        setProducts(sortedItems);
        setTests(testResult);
        setAllProducts(sortedItems);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("test data", tests);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // console.log(`Option selected:`, selectedOption);
  };

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props} style={{ display: "flex", alignItems: "center" }}>
      {props.data.label === "INDIA" ? <BiRupee /> : <FiDollarSign />}
      {props.data.label}
    </Option>
  );

  // checkbox
  const [trigger, setTrigger] = useState(0);

  const [checkedList, setCheckedList] = useState([]);
  const [checked, setChecked] = useState(true);
  const GreenCheckbox = withStyles({
    root: {
      color: "#1565d8",
      "&$checked": {
        color: "#1565d8",
      },
    },
    checked: {},
  })((props) => (
    <Checkbox
      color="default"
      style={{
        // paddingLeft: 0,
        marginLeft: "-10px",
      }}
      {...props}
    />
  ));

  let checkTest =
    allProducts && allProducts.filter((list) => list.itemType === "Test");
  let checkPackage =
    allProducts && allProducts.filter((list) => list.itemType === "Package");

  const filterTest = () => {
    let filteredData = allProducts.filter((list) => list.itemType === "Test");
    setProducts(filteredData);
  };

  const filterPackage = () => {
    let filteredData = allProducts.filter(
      (list) => list.itemType === "Package"
    );
    setProducts(filteredData);
  };

  const filterAll = () => {
    setProducts(allProducts);
  };

  const calculateTotal = () => {
    totalAmount = 0;

    checkedList.forEach((e) => {
      let price = allProducts.find((p) => {
        return p.product_id === e;
      });
      if (checkedList.length) {
        return (totalAmount =
          totalAmount + priceArr[e] * price.pack_offer_price);
      }
      if (checkedList.length === 0) {
        return (totalAmount = 0);
      }
    });

    // priceArr.forEach((d) => {
    //   <div className="home__dashboard" style={{ marginTop: "15px" }}></div>;
    //   totalAmount += d;
    // });

    // console.log("totalAmount", totalAmount);
    // console.log("checkedList", checkedList);
    // console.log("priceArr", priceArr);
    setTrigger(Date.now() + 2);
  };
  const onCheck = (pid) => {
    totalAmount = 0;
    checkedList.push(pid);
    priceArr[pid] = 1;
    checkedList.forEach((e) => {
      let price = allProducts.find((p) => {
        return p.product_id === e;
      });

      return (totalAmount = totalAmount + priceArr[e] * price.pack_offer_price);
    });
    // console.log("totalAmount1", totalAmount);
    // console.log("checkedList1", checkedList);
    // console.log("priceArr1", priceArr);
    setTrigger(Date.now() + 2);
  };

  const checkout = async () => {
    setSubmitLoading(true);
    let buy_products = checkedList
      .map((list) => {
        let pd = allProducts.find((product) => {
          // console.log(list);
          return product.product_id === list;
        });
        if (pd.itemType === "Package") {
          return {
            package_id: pd.package_id,

            qty: priceArr[list],
            // price: pd.offer_price,
            // test_name: pd.product_name,
            // test_type: selectedType.value,
          };
        }
      })
      .filter((d) => d !== undefined);

    let buy_tests = checkedList
      .map((list) => {
        let pd = allProducts.find((product) => {
          // console.log(list);
          return product.product_id === list;
        });
        if (pd.itemType === "Test") {
          return {
            test_id: pd.test_id,

            qty: priceArr[list],
            // price: pd.offer_price,
            // test_name: pd.product_name,
            // test_type: selectedType.value,
          };
        }
      })
      .filter((d) => d !== undefined);
    // console.log(buy_products);

    let data = {
      lab_id: selectedHospital.value,
      type: selectedType.value,
      // total: totalAmount,
    };

    if (checkTest.length && checkPackage.length) {
      data = {
        ...data,
        tests: buy_tests,
        packages: buy_products,
      };
    } else if (checkTest.length) {
      data = {
        ...data,
        tests: buy_tests,
        packages: [],
      };
    } else if (checkPackage.length) {
      data = {
        ...data,
        packages: buy_products,
        tests: [],
      };
    }

    console.log("checkout data", data);

    try {
      const result = await axios.post(
        Global.base_url + "/user/book/addtocart",
        data,
        config
      );
      if (result.status === 200) {
        setSubmitLoading(false);
        return history.push("/user/cart");
      }
    } catch (error) {
      // console.log(error);
      if (error.response && error.response.status === 401) {
        setSubmitLoading(false);
        return toast.error("cart creation failed!");
      }
    }
  };
  const PackageData = () => {
    return products.length > 0 ? (
      products.map((item, i) => {
        // let splitedInclusion =
        //   item.test_inclusion && item.test_inclusion.split(",");
        // let sortedInclusion =
        //   item.test_inclusion &&
        //   splitedInclusion.map((item) => {
        //     return item.split(":");
        //   });
        return (
          <div className="row test__items mb-3" style={{ margin: 0 }} key={i}>
            <div className="col-12 col-sm-9 col-lg-10">
              <div className="test__itemHeader">
                <div className="test__firstcol">
                  <GreenCheckbox
                    checked={
                      checkedList.includes(item.product_id) ? checked : !checked
                    }
                    onChange={() =>
                      checkedList.includes(item.product_id)
                        ? setCheckedList(
                            checkedList.filter((list) => {
                              priceArr[item.product_id] = 0;

                              return item.product_id !== list;
                            }),
                            calculateTotal()
                          )
                        : onCheck(item.product_id)
                    }
                    name="checkbox"
                  />
                  <p className="test__title">
                    {item.package_name}{" "}
                    <Tooltip title={item.pack_recommend} placement="top-center">
                      <IconButton>
                        <IconContext.Provider
                          value={{
                            size: "20px",
                            color: "#333",
                          }}
                        >
                          <AiOutlineExclamationCircle />
                        </IconContext.Provider>
                      </IconButton>
                    </Tooltip>
                  </p>
                </div>
                <div className="booking__description d-flex justify-content-start">
                  <p className="semiFont" style={{ color: "#8B8B8B" }}>
                    {item.package_desc}
                    <span
                      style={{
                        color: "#F1B83B",
                        marginLeft: "10px",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      {item.pack_offer_text}.
                    </span>
                  </p>
                </div>
                {item.pack_linked === 1 && (
                  <p className="semiFont" style={{ color: "#8B8B8B" }}>
                    {item.testData &&
                      item.testData.map((item) => {
                        return (
                          <>
                            <span style={{ color: "#333" }}>
                              {item.test_for}
                            </span>
                            : {item.test_name}{" "}
                          </>
                        );
                      })}
                  </p>
                )}
                {item.pack_linked === 0 && (
                  <p className="semiFont" style={{ color: "#8B8B8B" }}>
                    {/* {sortedInclusion.map((item) => {
                        return (
                          <>
                            <span style={{ color: "#333" }}>{item[0]}</span>:{" "}
                            {item[1]},{" "}
                          </>
                        );
                      })} */}
                    {item.test_inclusion}
                  </p>
                )}
                <p className="test__discount">
                  <BiRupee />
                  <span className="oldAmount">{item.package_price}</span>
                  <span className="newAmount" style={{ marginLeft: "10px" }}>
                    {item.pack_offer_price}/-{" "}
                  </span>
                </p>
              </div>
            </div>
            <div className="row col-sm-3 col-lg-2 item-padding mr-0">
              <div className="test__totalAmount">
                <p className="semiFont" style={{ color: "#333" }}>
                  Total
                </p>
                <div className="test__amount">
                  <BiRupee />
                  <p className="newAmount">
                    {priceArr[item.product_id]
                      ? priceArr[item.product_id] * item.pack_offer_price
                      : 0}
                  </p>
                </div>
              </div>

              <div className="test__description ml-sm-0 ml-auto">
                <div className="test__texture"></div>
                <div className="inputbox">
                  <NumericInput
                    className="form-control"
                    value={
                      checkedList.includes(item.product_id)
                        ? priceArr[item.product_id]
                        : 0
                    }
                    key={item.product_id}
                    min={1}
                    max={5}
                    step={1}
                    precision={0}
                    size={6}
                    strict
                    mobile
                    disabled={
                      checkedList.includes(item.product_id) ? false : true
                    }
                    onChange={(value) => {
                      return checkedList.includes(item.product_id)
                        ? ((priceArr[item.product_id] = value),
                          calculateTotal())
                        : ((priceArr[item.product_id] = 0), calculateTotal());
                    }}
                    inputMode="numeric"
                    style={{
                      wrap: {
                        background: "tranperent",
                        boxShadow: "none",
                        padding: "2px 2px 2px 2px",
                        borderRadius: "6px 3px 3px 6px",
                        border: "1px solid #B3CDFF",
                        touchAction: "none",
                      },
                      input: {
                        width: "100px",
                        borderRadius: "4px 2px 2px 4px",
                        color: "#333",
                        display: "block",
                        fontFamily: "mediumFont",
                        fontWeight: 600,
                        border: "none",
                      },
                      "input:focus": {
                        border: "none !important",
                        outline: "none",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="center__items p-5" style={{ flexDirection: "column" }}>
        <img src={emptyProduct} alt="Empty Product" />
        <p className="header__txt mt-2" style={{ fontSize: "20px" }}>
          Packages not found
        </p>
      </div>
    );
  };

  const inputCustomStyles = {
    control: (base, state) => ({
      ...base,
      padding: "10px",
      background: "#fff",
      border: state.isFocused ? "1px solid #B3CDFF80" : "1px solid #B3CDFF80",
      outline: "none",
      "&:active": {
        border: state.isFocused ? "1px solid #B3CDFF80" : "1px solid #B3CDFF80",
        outline: "none",
        boxShadow: "none",
      },
      "&:hover": {
        border: state.isFocused ? "1px solid #B3CDFF80" : "1px solid #B3CDFF80",
        outline: "none",
        boxShadow: "none",
      },
    }),
    menu: (base) => ({
      ...base,
      border: "none",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#eee" : "#fff",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      fontFamily: "SemiFont",
      color: state.isFocused ? "#5a7184" : "#5a7184",
      "&:hover": {
        backgroundColor: state.isFocused ? "#eee" : "#fff",
      },
    }),
    menuList: (base) => ({
      ...base,
      border: "1px solid #eee",
    }),
  };

  // test center dropdown

  const searchItems = (event) => {
    let filteredProduct = allProducts.filter((list) => {
      return event !== null
        ? list.package_name
            .toString()
            .toLowerCase()
            .includes(event.toLowerCase())
        : allProducts;
    });
    setProducts(filteredProduct);
  };

  return (
    <>
      <>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          // newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        <section className="test py-3">
          <div className="test__container">
            <div className="test__centerContainer">
              <div className="test__centerTitle mb-4">
                <IconButton onClick={() => history.push("/user/dashboard")}>
                  <IconContext.Provider value={{ size: "30px" }}>
                    <FiArrowLeft />
                  </IconContext.Provider>
                </IconButton>
                <p
                  className="boldFont"
                  style={{
                    color: "#161616",
                    fontSize: "25px",
                    marginLeft: "10px",
                  }}
                >
                  Choose center
                </p>
                {/* <IoMdClose onClick={() => history.push("/user/dashboard")} /> */}
              </div>
              <div
                className="inputGroup d-flex flex-wrap"
                style={{ gap: "15px" }}
              >
                <Select
                  value={selectedType}
                  onChange={(c) => getStates(c)}
                  options={testOptions}
                  className="inputDropdown"
                  id="inputDropdown"
                  placeholder={"Choose test type"}
                  styles={inputCustomStyles}
                />
                {/* <span style={{ marginLeft: "10px" }}>{""}</span> */}
                <Select
                  value={selectedState}
                  onChange={(state) => getCities(state)}
                  options={states}
                  className="inputDropdown"
                  id="inputDropdown"
                  placeholder={"State"}
                  styles={inputCustomStyles}
                />
                <Select
                  value={selectedCity}
                  onChange={(city) => getHospitals(city)}
                  options={cities}
                  className="inputDropdown"
                  id="inputDropdown"
                  placeholder={"City"}
                  styles={inputCustomStyles}
                />
                {/* <span style={{ marginLeft: "10px" }}>{""}</span> */}
                <div>
                  <Select
                    value={selectedHospital}
                    onChange={(center) => getProducts(center)}
                    options={hospitals}
                    className="inputDropdown"
                    id="inputDropdown"
                    placeholder={"Choose test center"}
                    styles={inputCustomStyles}
                  />
                </div>
              </div>
            </div>
            {allProducts.length ? (
              <>
                <div className="test__titleText">
                  <div className="test__packageinfo">
                    <p
                      className="semiFont"
                      style={{
                        color: "#333",
                        fontSize: "20px",
                        marginRight: "10px",
                      }}
                    >
                      Choose Package
                    </p>
                    <div>
                      {checkTest.length && checkPackage.length ? (
                        <>
                          <Button className="text__button" onClick={filterAll}>
                            All
                          </Button>

                          <Button className="text__button" onClick={filterTest}>
                            Test
                          </Button>
                          <Button
                            className="text__button"
                            onClick={filterPackage}
                          >
                            Package
                          </Button>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="filter__container"
                    style={{ position: "relative" }}
                  >
                    <input
                      type="text"
                      className="filter__input"
                      placeholder="Search Package"
                      onChange={(e) => {
                        searchItems(e.target.value);
                      }}
                    />
                    <IconContext.Provider
                      value={{
                        size: "25px",
                        color: "#333",
                      }}
                    >
                      <IoSearchOutline
                        className="filter-search-icon"
                        color="#818181"
                        style={{ cursor: "pointer" }}
                      />
                    </IconContext.Provider>
                  </div>
                </div>
                <div className="test__itemContainer mt-3">
                  <PackageData />
                </div>
                {products.length ? (
                  <div className="row mt-3 align-contents-right justify-content-end">
                    <div className=" col-12 mt-md-0 mt-3 col-md-3 text-left">
                      <div className="test__checkout">
                        <div className="test__checkoutAmount">
                          <div className="test__checkoutItem">
                            <p className="test__summary">Summary</p>
                          </div>
                          <div className="d-flex align-items-center w-100 justify-content-between">
                            <p
                              className="semiFont"
                              style={{ color: "#8B8B8B" }}
                            >
                              {checkedList.length} item
                            </p>
                            <p className="test__total">
                              <BiRupee />

                              {checkedList.length > 0 ? totalAmount : 0}
                            </p>
                          </div>

                          {/* <p className="test__total">
                    <BiRupee />

                    {checkedList.length > 0 ? totalAmount : 0}
                  </p> */}
                        </div>
                        <div className="w-100 text-right mt-2">
                          <Button
                            variant="contained"
                            className={
                              totalAmount > 0
                                ? "test__checkoutBtn"
                                : "test__chekout_disabled"
                            }
                            style={{
                              // borderRadius: "none",
                              width: "inherit",
                              padding: "10px",
                              // fontFamily: "boldFont",
                            }}
                            onClick={checkout}
                            disabled={
                              totalAmount === 0 || submitLoading ? true : false
                            }
                          >
                            {submitLoading ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Add to Cart"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </section>
      </>
      {/* </main> */}
    </>
  );
}

export default Book;
