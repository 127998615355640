import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import csc from "country-state-city";
import { Formik, ErrorMessage, Field, FieldArray, Form } from "formik";
import * as yup from "yup";
import "yup-phone";
import "../assets/css/cart.css";
// import creditcard from "../assets/images/creditcard.png";
// import paypal from "../assets/images/paypal.png";
// import CreditCardInput from "react-credit-card-input";
// import Header from "./Header";
import Global from "../global";
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import Loader from "react-loader-spinner";
import axios from "axios";
import Select from "react-select";
import { propTypes } from "react-bootstrap/esm/Image";
import { FaCartPlus } from "react-icons/fa";
import { DateTimePicker } from "@material-ui/pickers";
import { IconContext } from "react-icons";
import MomentUtils from "@date-io/moment";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";

const Billing = (props) => {
  const history = useHistory();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
  const requiredField = <span style={{ color: "red" }}>*</span>;
  const [countries, setCountries] = useState([{ name: "Choose Country" }]);
  const [field, addField] = useState(false);
  const [paymentinfo, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [states, setStates] = useState([{ name: "Choose State" }]);
  const [cities, setCities] = useState([{ name: "Choose City" }]);

  const [items, setitem] = useState([
    { item: "Basic", price: 3500, qty: 1 },
    { item: "Premium", price: 1500, qty: 2 },
  ]);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    // const country = csc.getAllCountries();
    // setCountries([...countries, ...country]);
    async function fetchinfo() {
      const country = csc.getAllCountries();

      try {
        const result = await axios.post(
          Global.base_url + "/user/cart/review",
          null,
          config
        );

        if (result.status === 200) {
          console.log("billing response: ", result);
          setInfo(result.data);
          setCountries([...countries, ...country]);
          return setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.code === 401
          ) {
            console.log("user error", error.response.status);
            return;
          }
        }
      }
    }
    fetchinfo();
  }, [toggle]);

  const getAllStates = (e) => {
    // const noOption = { label: "Choose State", value: "" };
    const country = countries.find((c) => c.name === e);
    console.log({ country });
    setStates([...csc.getStatesOfCountry(country.isoCode)]);
  };
  const getAllCities = (e) => {
    // const noOption = { label: "Choose City", value: "" };
    const state = states.find((s) => s.name === e);

    setCities([...csc.getCitiesOfState(state.countryCode, state.isoCode)]);
  };

  console.log("payment_info", paymentinfo);

  const aadhaarRegex = "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$";
  const validationSchema = yup.object({
    firstname: yup.string().required("Firstname cannot be empty"),

    lastname: yup.string().required("Lastname cannot be empty"),

    country: yup.string().required("Please select Country"),
    state: yup.string().required("Please select State").nullable(),
    city: yup.string().required("Please select City").nullable(),
    address: yup.string().required("address cannot be empty"),
    // paymenttype: yup.string().required(),
    // creditcard: yup.string().when("paymenttype", (type) => {
    //   if (type === "credit") {
    //     return yup.string().required("credit card cannot be empty");
    //   }
    // }),
    // expiry: yup.string().when("paymenttype", (type) => {
    //   if (type === "credit") {
    //     return yup.string().required("expiry cannot be empty");
    //   }
    // }),
    // cvv: yup.string().when("paymenttype", (type) => {
    //   if (type === "credit") {
    //     return yup.string().required("expiry cannot be empty").min(3).max(3);
    //   }
    // }),

    pincode: yup.string().required("Pincode cannot be empty"),
    phone: yup
      .string()
      .phone("Enter valid phone number")
      .required("Phone cannot be empty"),
  });

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <Formik
          initialValues={{
            firstname: paymentinfo.user[0].first_name,
            lastname: paymentinfo.user[0].last_name,
            address: paymentinfo.user[0].address,
            country: paymentinfo.user[0].country,
            state: paymentinfo.user[0].state,
            city: paymentinfo.user[0].district,
            pincode: paymentinfo.user[0].pincode,
            phone: paymentinfo.user[0].phone_no,
          }}
          onSubmit={async (values) => {
            setPaymentLoading(true);
            // console.log(values);

            try {
              const response = await axios.post(
                Global.base_url + "/user/cart/checkout",
                {
                  first_name: values.firstname,
                  last_name: values.lastname,
                  district: values.city,
                  state: values.state,
                  country: values.country,
                  phone_no: values.phone,
                  pincode: values.pincode,
                  address: values.address,
                },
                config2
              );

              if (response.status === 200) {
                const result = await axios.post(
                  Global.base_url + "/user/payment",
                  null,
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                );
                // console.log("paymentdetailsresult", result);
                if (result.status === 200) {
                  setPaymentLoading(false);
                  toast.success("Successfully Booked");
                  history.replace({
                    pathname: "/user/dashboard",
                  });
                }
              }
            } catch (error) {
              setPaymentLoading(false);
              console.log(error);
              if (error.response && error.response.status === 500) {
                return toast.error("Payment Failed");
              }
            }
          }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formik) => {
            console.log("formik.values", formik.errors);
            return (
              <>
                {/* <Header /> */}
                <div className="bill-container">
                  <nav className="test__centerTitle mb-4 px-5 py-3">
                    <p
                      className="hkBoldFont"
                      style={{ color: "#161616", fontSize: "35px" }}
                    >
                      Checkout
                    </p>
                  </nav>
                  {Object.keys(paymentinfo).length ? (
                    <div className="row mt-4 mb-sm-0 mb-md-0 justify-content-center mr-0 pb-5">
                      <div
                        className="col-lg-7 col-xl-7 row-md row-sm mr-1"
                        style={{
                          width: "90%",
                        }}
                      >
                        <h3 className="shipform--Header">
                          SHIPPING /BILLING DETAILS
                        </h3>

                        <div className="row mt-3 justify-content-center">
                          <div className="col-lg col-xl col-12">
                            <div
                              className="inputBox"
                              style={{
                                position: "relative",
                                marginTop: 0,
                                marginBottom: "10px",
                              }}
                            >
                              <label
                                htmlFor="firstname"
                                className="semiFont login__label"
                              >
                                First Name {requiredField}
                              </label>
                              <input
                                type="text"
                                value={formik.values.firstname}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "firstname",
                                    e.currentTarget.value.replace(
                                      /[^a-zA-Z ]/g,
                                      ""
                                    )
                                  )
                                }
                                style={{
                                  border: formik.errors.firstname
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  marginTop: "5px",
                                  width: "auto",
                                }}
                                id="firstname"
                                aria-describedby="firstname"
                                placeholder="First name"
                              />
                              {formik.errors.firstname && (
                                <p className="err">{formik.errors.firstname}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg col-xl col-12">
                            <div
                              className="inputBox"
                              style={{
                                position: "relative",
                                marginTop: 0,
                                marginBottom: "10px",
                              }}
                            >
                              <label
                                htmlFor="lastname"
                                className="semiFont login__label"
                              >
                                Last Name {requiredField}
                              </label>
                              <input
                                type="text"
                                value={formik.values.lastname}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "lastname",
                                    e.currentTarget.value.replace(
                                      /[^a-zA-Z ]/g,
                                      ""
                                    )
                                  )
                                }
                                style={{
                                  border: formik.errors.lastname
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  marginTop: "5px",
                                  width: "auto",
                                }}
                                id="lastname"
                                aria-describedby="lastname"
                                placeholder="Last name"
                              />
                              {formik.errors.lastname && (
                                <p className="err">{formik.errors.lastname}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 justify-content-center">
                          <div className="col-lg col-xl col-12">
                            <div
                              className="inputBox"
                              style={{
                                position: "relative",
                                marginTop: 0,
                                marginBottom: "10px",
                              }}
                            >
                              <label
                                htmlFor="address"
                                className="semiFont login__label"
                              >
                                Address {requiredField}
                              </label>
                              <textarea
                                id="address"
                                rows="6"
                                spellCheck={false}
                                style={{
                                  border: formik.errors.address
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  marginTop: "5px",
                                  width: "auto",
                                  resize: "none",
                                  borderRadius: "8px",
                                  padding: "10px",
                                  color: "#183b56",
                                  fontSize: "17px",
                                  fontFamily: "semiFont",
                                }}
                                value={formik.values.address}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "address",
                                    e.currentTarget.value.replace(
                                      /[^a-zA-Z0-9-_+.,/' ]/g,
                                      ""
                                    )
                                  );
                                }}
                              ></textarea>

                              {formik.errors.address && (
                                <p className="err">{formik.errors.address}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg col-xl col-12">
                            <div
                              className="inputBox mt-3"
                              style={{
                                position: "relative",
                                marginTop: 0,
                                marginBottom: "10px",
                              }}
                            >
                              <label
                                htmlFor="country"
                                className="semiFont login__label"
                              >
                                Country {requiredField}
                              </label>
                              <Select
                                value={{
                                  label: formik.values.country,
                                  value: formik.values.country,
                                }}
                                onChange={(p) => {
                                  getAllStates(p.value);
                                  formik.setFieldValue("country", p.value);
                                  formik.setFieldValue("state", null);
                                  formik.setFieldValue("city", null);
                                }}
                                // defaultValue=""
                                options={countries.map((c) => {
                                  return { value: c.name, label: c.name };
                                })}
                                id="inputDropdown"
                                name="country"
                                className="admim__Addproduct--dropdown "
                                placeholder="Select Country"
                                // id="state"
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    marginTop: "5px",
                                    padding: "10px 10px",
                                    border: formik.errors.country
                                      ? "1px solid red"
                                      : "1px solid #c3cad9",
                                    // border: "1px solid #red",
                                    "&:active": {
                                      outline: "none",
                                      boxShadow: "none",
                                    },
                                    "&:hover": {
                                      outline: "none",
                                      boxShadow: "none",
                                    },
                                  }),
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                    padding: "8px",
                                    display: "flex",
                                    fontFamily: "semiFont",
                                    alignItems: "center",
                                    fontSize: "16px",
                                    zIndex: 9999,
                                    color: state.isFocused
                                      ? "#183b56"
                                      : "#183b56",
                                    "&:hover": {
                                      backgroundColor: state.isFocused
                                        ? "#eee"
                                        : "#fff",
                                    },
                                  }),
                                }}
                              />

                              {formik.errors.country && (
                                <div className="err">
                                  <p>{formik.errors.country}</p>
                                </div>
                              )}
                            </div>

                            <div
                              className="inputBox"
                              style={{
                                position: "relative",
                                marginTop: 0,
                                marginBottom: "10px",
                              }}
                            >
                              <label
                                htmlFor="state"
                                className="semiFont login__label"
                              >
                                State {requiredField}
                              </label>
                              <Select
                                value={{
                                  label: formik.values.state,
                                  value: formik.values.state,
                                }}
                                onChange={(p) => {
                                  getAllCities(p.value);
                                  formik.setFieldValue("state", p.value);
                                  formik.setFieldValue("city", null);
                                }}
                                // defaultValue=""
                                options={states.map((s) => {
                                  return { value: s.name, label: s.name };
                                })}
                                id="inputDropdown"
                                name="state"
                                // className="admim__Addproduct--dropdown "
                                placeholder="Select State"
                                // id="state"
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    marginTop: "5px",
                                    padding: "10px 10px",
                                    border: formik.errors.state
                                      ? "1px solid red"
                                      : "1px solid #c3cad9",
                                    "&:active": {
                                      outline: "none",
                                      boxShadow: "none",
                                    },
                                    "&:hover": {
                                      outline: "none",
                                      boxShadow: "none",
                                    },
                                  }),
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                    padding: "8px",
                                    display: "flex",
                                    fontFamily: "semiFont",
                                    alignItems: "center",
                                    fontSize: "16px",
                                    zIndex: 9999,
                                    color: state.isFocused
                                      ? "#183b56"
                                      : "#183b56",
                                    "&:hover": {
                                      backgroundColor: state.isFocused
                                        ? "#eee"
                                        : "#fff",
                                    },
                                  }),
                                }}
                              />

                              {formik.errors.state && (
                                <div className="err">
                                  <p>{formik.errors.state}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-lg col-xl col-12">
                            <div
                              className="inputBox"
                              style={{
                                position: "relative",
                                marginTop: 0,
                                marginBottom: "10px",
                              }}
                            >
                              <label
                                htmlFor="city"
                                className="semiFont login__label"
                              >
                                City {requiredField}
                              </label>
                              <Select
                                value={{
                                  label: formik.values.city,
                                  value: formik.values.city,
                                }}
                                onChange={(p) => {
                                  formik.setFieldValue("city", p.value);
                                }}
                                options={cities.map((c) => {
                                  return { value: c.name, label: c.name };
                                })}
                                id="inputDropdown"
                                name="city"
                                className="admim__Addproduct--dropdown "
                                placeholder="Select City"
                                // id="state"
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    marginTop: "5px",
                                    padding: "10px 10px",
                                    border: formik.errors.city
                                      ? "1px solid red"
                                      : "1px solid #c3cad9",
                                    //   border: '1px solid red'
                                    "&:active": {
                                      outline: "none",
                                      boxShadow: "none",
                                    },
                                    "&:hover": {
                                      outline: "none",
                                      boxShadow: "none",
                                    },
                                  }),
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                    padding: "8px",
                                    display: "flex",
                                    fontFamily: "semiFont",
                                    alignItems: "center",
                                    fontSize: "16px",
                                    zIndex: 9999,
                                    color: state.isFocused
                                      ? "#183b56"
                                      : "#183b56",
                                    "&:hover": {
                                      backgroundColor: state.isFocused
                                        ? "#eee"
                                        : "#fff",
                                    },
                                  }),
                                }}
                              />

                              {formik.errors.city && (
                                <div className="err">
                                  <p>{formik.errors.city}</p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg col-xl col-12">
                            <div
                              className="inputBox"
                              style={{
                                position: "relative",
                                marginTop: 0,
                                marginBottom: "10px",
                              }}
                            >
                              <label
                                htmlFor="pincode"
                                className="semiFont login__label"
                              >
                                Postcode / ZIP {requiredField}
                              </label>
                              <input
                                type="text"
                                value={formik.values.pincode}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "pincode",
                                    e.currentTarget.value.replace(
                                      /[^\w\s]/gi,
                                      ""
                                    )
                                  )
                                }
                                style={{
                                  border: formik.errors.pincode
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  marginTop: "5px",
                                  width: "auto",
                                }}
                                id="pincode"
                                aria-describedby="pincode"
                                placeholder="Pincode"
                              />
                              {formik.errors.pincode && (
                                <p className="err">{formik.errors.pincode}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-center">
                          <div className="col-lg col-xl col-12">
                            <div
                              className="inputBox"
                              style={{
                                position: "relative",
                                marginTop: 0,
                                marginBottom: "10px",
                              }}
                            >
                              <label
                                htmlFor="phone"
                                className="semiFont login__label"
                              >
                                Phone {requiredField}
                              </label>
                              <input
                                type="tel"
                                value={formik.values.phone}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "phone",
                                    e.currentTarget.value.replace(/[^0-9]/g, "")
                                  )
                                }
                                style={{
                                  border: formik.errors.phone
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  marginTop: "5px",
                                  width: "auto",
                                }}
                                id="phone"
                                aria-describedby="phone"
                                placeholder="phone"
                              />
                              {formik.errors.phone && (
                                <p className="err">{formik.errors.phone}</p>
                              )}
                            </div>
                          </div>
                          {/* <div className="col-6 ">
                                        <p className="" style={{ fontSize: "12px" }}>
                                          Enter ZIP for City & State
                                        </p>
                                      </div> */}
                        </div>
                      </div>

                      <div
                        className="col-lg-4 col-xl-4 row-md row-sm   "
                        style={{ width: "90%" }}
                      >
                        <h3 className="shipform--Header">YOUR ORDER</h3>
                        {/* <div class="d-flex justify-content-between mt-2 pb-1">
                          <span
                            className="order-Title"
                            style={{ fontSize: "13px" }}
                          >
                            Test Type :
                          </span>
                          <span
                            className="order-Title"
                            style={{ fontSize: "13px" }}
                          >
                            {paymentinfo.cart.products[0].test_type}
                          </span>
                        </div> */}
                        {/* <div class="d-flex justify-content-between mt-2 pb-1">
                          <span
                            className="order-Title"
                            style={{ fontSize: "13px" }}
                          >
                            Date Requested:
                          </span>
                          <span
                            className="order-Title"
                            style={{ fontSize: "13px" }}
                          >
                            {moment(paymentinfo.cart.req_date).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                        </div> */}

                        <div className="d-flex justify-content-between mt-3 mb-1 pb-1">
                          <span
                            className="order-Title"
                            // style={{ fontSize: "13px" }}
                          >
                            Product
                          </span>
                          <span
                            className="order-Title"
                            // style={{ fontSize: "13px" }}
                          >
                            Total
                          </span>
                        </div>
                        <div
                          className="d-flex"
                          style={{ border: "1px solid #D2D2D2" }}
                        ></div>
                        {paymentinfo.items.map((item) => {
                          return (
                            <>
                              <div className="d-flex justify-content-between pt-2 pb-2">
                                <span
                                  style={{
                                    fontSize: "17px",
                                    color: "#616161",
                                    fontFamily: "mediumFont",
                                  }}
                                >
                                  {item.test_name} × {Number(item.total_items)}
                                </span>
                                <span
                                  style={{
                                    fontSize: "17px",
                                    color: "#616161",
                                    fontFamily: "mediumFont",
                                  }}
                                >
                                  {Number(item.unit_price) *
                                    Number(item.total_items)}
                                </span>
                              </div>
                              <div
                                className="d-flex"
                                style={{ border: "1px solid #D2D2D2" }}
                              ></div>
                            </>
                          );
                        })}

                        <div className="d-flex justify-content-between pb-2 pt-2">
                          <span
                            className="order-Title"
                            style={{ fontSize: "16px" }}
                          >
                            SHIPPING
                          </span>
                          <span
                            className="order-Title"
                            style={{
                              fontSize: "16px",
                              fontFamily: "boldFont",
                            }}
                          >
                            FREE SHIPPING
                          </span>
                        </div>
                        <div
                          className="d-flex"
                          style={{ border: "1px solid #D2D2D2" }}
                        ></div>
                        <div className="d-flex justify-content-between pb-2 pt-2">
                          <span
                            className="order-Title"
                            style={{ fontSize: "18px" }}
                          >
                            Total
                          </span>
                          <span
                            className="order-Title"
                            style={{ fontSize: "18px" }}
                          >
                            <span>&#8377;</span>
                            {paymentinfo.amount}
                          </span>
                        </div>

                        <div
                          className="d-flex   align-items-start payment-info mediumFont"
                          style={{ fontSize: "15px" }}
                        >
                          Your personal data will be used to process your order,
                          support your experience throughout this website, and
                          for other purposes described in our privacy policy.
                        </div>
                        <div className="d-flex   align-items-start payment-info mt-4">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={formik.handleSubmit}
                            className={"Payment__button"}
                            disabled={paymentLoading ? true : false}
                            style={{
                              padding: "10px 40px",
                              backgroundColor: "#1565d8",
                              textTransform: "initial",
                              fontSize: "18px",
                              fontFamily: "boldFont",
                            }}
                            //   disabled={true}
                          >
                            {paymentLoading ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Place Order"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <IconContext.Provider
                        value={{ color: "#D2D2D2", size: "100px" }}
                      >
                        <FaCartPlus />
                      </IconContext.Provider>
                      <p
                        style={{
                          color: "#D2D2D2",
                          padding: "20px",
                          fontSize: "20px",
                          fontFamily: "mediumFont",
                        }}
                      >
                        Cart is empty...
                      </p>
                    </div>
                  )}
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default Billing;
